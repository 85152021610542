
/**
 *************** Reset CSS ***************
*/


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
     -moz-box-sizing:border-box;
     -webkit-box-sizing:border-box;
     -ms-box-sizing:border-box;
  font-family: 'Aspira', sans-serif; // Global font-family (Aspira) Se puede cambiar por otra fuente
  font-style: normal;
  font-size: 16px;
 }

/**
 *************** Global Styles ***************
*/

/**
 * backgrounds
*/

.#{$prefix}all-viewport {
  // max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}

.#{$prefix}background-app {
  background-image: url("../assets/img/Background_Aforos_genXapp.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-backdrop-filter: brightness(0.92);
  backdrop-filter: brightness(0.92);
}

// Responsive mixins using "Material Design" breakpoints


