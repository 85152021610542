
/**
******** Prefixes ********
*/

$prefix: "agenx-";

@function prefix($property, $value) {
  $prefixes: (
    '-webkit-',
    '-moz-',
    '-ms-',
    '-o-',
    ''
  );

  $prefixed-values: ();

  @each $prefix in $prefixes {
    $prefixed-values: append($prefixed-values, $prefix + $property + ': ' + $value + ';');
  }

  @return $prefixed-values;
}

/**
******** Mixins Responsive ********
*/

@mixin small {
  @media only screen and (max-width: 600px) {
    @content;
  }
}

@mixin medium {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin large {
  @media only screen and (max-width: 1440px) {
    @content;
  }
}

/**
******** Paddings/Margins ********
*/

.#{$prefix}p--0 {
  padding: 0;
}

.#{$prefix}p--1 {
  padding: 0.25rem;
}

.#{$prefix}p--2 {
  padding: 0.5rem;
}

.#{$prefix}p--3 {
  padding: 0.75rem;
}

.#{$prefix}p--4 {
  padding: 1rem;
}

.#{$prefix}p--5 {
  padding: 1.25rem;
}

.#{$prefix}p--6 {
  padding: 1.5rem;
}

.#{$prefix}p--7 {
  padding: 1.75rem;
}

.#{$prefix}p--8 {
  padding: 2rem;
}

.#{$prefix}p--9 {
  padding: 3rem;
}

.#{$prefix}p--10 {
  padding: 4rem;
}

.#{$prefix}m--0 {
  margin: 0;
}

.#{$prefix}m--1 {
  margin: 0.25rem;
}

.#{$prefix}m--2 {
  margin: 0.5rem;
}

.#{$prefix}m--3 {
  margin: 0.75rem;
}

.#{$prefix}m--4 {
  margin: 1rem;
}

.#{$prefix}m--5 {
  margin: 1.25rem;
}

.#{$prefix}m--6 {
  margin: 1.5rem;
}

.#{$prefix}m--7 {
  margin: 1.75rem;
}

.#{$prefix}m--8 {
  margin: 2rem;
}

.#{$prefix}m--9 {
  margin: 3rem;
}

.#{$prefix}m--10 {
  margin: 4rem;
}

.#{$prefix}mb--0 {
  margin-bottom: 0;
}

.#{$prefix}mb--1 {
  margin-bottom: 0.25rem;
}

.#{$prefix}mb--2 {
  margin-bottom: 0.5rem;
}

.#{$prefix}mb--3 {
  margin-bottom: 0.75rem;
}

.#{$prefix}mb--4 {
  margin-bottom: 1rem;
}

.#{$prefix}mt--4 {
  margin-top: 1rem;
}

// @for $i from 4 through 10 {
//   .#{$prefix}mb--#{$i} {
//     margin-bottom: #{$i}rem;
//   }
// }


/**
******** Animation ********
*/

:root {
  --transition-time: 0.3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes Spiral {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

