.#{$prefix}title {
  font-size: 0.8rem;
  font-weight: 200;
  color: var(--gray-base);
}

.width-input {
  width: 30vw;
}

.middle {
  display: flex;
  align-items: center;
}

.no-line {
  text-decoration: none;
}

.error-btn {
  color: #E54544;
  font-size: x-small;
}
