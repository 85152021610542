
/* Colors */


:root {

  /* Primnary */

  --blue-base: #498DC2;
  --lightblue-base: #3DC5FF;
  --orange-base: #FF9933;

  /* Specials */

  --lightgreen-base: #39B54A;
  --green-base: #6A8029;
  --red-base: #E54544;

  /* Standard */

  --white-base: #FFFFFF;
  --gray-base: #465E66;
  --lightgray-base: #CCCCCC;

  /* Extras */

  --lightgray-extra: #e4e4e6;
  --lightgreen-extra: #7dce88;
  --red-extra: #af1c1e;

}
