@font-face {
  font-family: Aspira;
  src: url(../assets/fonts/Aspira__Regular.otf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Aspira;
  src: url(../assets/fonts/Aspira__Italic.otf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Aspira;
  src: url(../assets/fonts/Aspira__Light.otf);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Aspira;
  src: url(../assets/fonts/Aspira__Bold.otf);
  font-weight: 600;
  font-style: normal;
}
